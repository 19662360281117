<template>
  <div>
    <!-- <this-header /> -->
    <b-overlay
      :show="show"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <!-- <b-icon-hourglass-split
            font-scale="3"
            animation="cylon"
          /> -->

          <p
            id="cancel-label"
            class="neonPink"
          >
            <img
              src="/logo/logo.png"
              alt="logo"
              width="100px"
            ><br>
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <div class="container-new pd2 pb-5">

        <!-- <b-row>
          <b-col
            cols="12"
            style="margin-top: 30px;"
          >
            <div class="header-bg-content mb-1 bg-dark-red-3">
              <div class="mb-1 d-flex justify-content-center">
                <span class="head-anouce">
                  ประกาศ
                </span>
              </div>
              <div
                v-html="'<h3><b><u>ทดสอบประกาศ HTML</b><u><h3>'"
              />
            </div>
          </b-col>
        </b-row> -->
        <!-- <div
          class="d-flex align-items-center"
          style="margin-top:90px;"
        >
          <div class="txt-right bg-dark-red-1 d-flex justify-content-between text-dark">
            <span class="txt-right__text">ผลรางวัลทั้งหมด</span>
          </div>
        </div> -->
        <b-row class="mt-2 text-center">
          <b-col lg="12">
            <div
              class="text-center mb-1 mt-6 block neon"
              style="margin: 140px auto 0;"
            >
              <div class="mb-1 d-flex justify-content-center">
                <div class="head1">
                  <img
                    src="/blackpink/head/head1.png"
                    alt=""
                  >
                  <br>
                  <h4
                    style="width:300px;height:50px;margin-top:10px;"
                    class="text-left text-pink ml-1"
                  >
                    <img
                      src="/flag/th.png"
                      alt=""
                      height="20"
                    > ผลสลากกินแบ่งรัฐบาล
                  </h4>
                </div>
                <h3 class="text-white mt-3">

                  งวดประจำวันที่ {{ ResultThaiDate ? ResultThaiDate : "วว/ดด/ปปปป" }}
                  <br>
                </h3>
              </div>
              <b-row>
                <b-col
                  lg="12"
                  cols="12"
                  class="mb-1"
                >
                  <h1 class="m-0 text-pink">
                    {{ ResultThai.R1 ? ResultThai.R1 : "xxx" }}
                  </h1>
                  <small>รางวัลที่ 1</small>
                </b-col>

                <b-col
                  lg="4"
                  cols="4"
                >
                  <h3 class="m-0 text-pink-1">
                    {{ ResultThai.F3N1 ? ResultThai.F3N1 : "xxx" }}
                    {{ ResultThai.F3N2 ? ResultThai.F3N2 : "xxx" }}
                  </h3>
                  <small>เลขหน้า 3 ตัว</small>
                </b-col>

                <b-col
                  lg="4"
                  cols="4"
                >
                  <h3 class="m-0 text-pink-1">
                    {{ ResultThai.B3N1 ? ResultThai.B3N1 : "xxx" }}
                    {{ ResultThai.B3N2 ? ResultThai.B3N2 : "xxx" }}
                  </h3>
                  <small>เลขท้าย 3 ตัว</small>
                </b-col>

                <b-col
                  lg="4"
                  cols="4"
                >
                  <h3 class="m-0 text-pink-1">
                    {{ ResultThai.D2 ? ResultThai.D2 : "xx" }}
                  </h3>
                  <small>สองตัวล่าง</small>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col
            lg="6"
            cols="12"
          >
            <!-- Lotto THAI -->
            <div class=" mb-1 block">
              <div class="mb-1 d-flex justify-content-center pt-2">
                <img
                  src="https://www.เปิดสอบราชการ.com/img/articles/158634671920200416_154512.png"
                  alt=""
                  height="20"
                >&nbsp;
                <span class="text-white">
                  หวย ออมสิน
                  {{ ResultAomDate ? ResultAomDate : "วว/ดด/ปปปป" }}
                </span>
              </div>
              <b-row>
                <b-col
                  lg="6"
                  cols="6"
                >
                  <h3 class="m-0 text-pink-1">
                    {{ ResultAom.U3 ? ResultAom.U3 : "xxx" }}
                  </h3>
                  <small>3ตัวบน</small>
                </b-col>

                <b-col
                  lg="6"
                  cols="6"
                >
                  <h3 class="m-0 text-pink-1">
                    {{ ResultAom.D2 ? ResultAom.D2 : "xx" }}
                  </h3>
                  <small>2ตัวล่าง</small>
                </b-col>
              </b-row>
            </div>
            <!-- End Lotto THAI -->
          </b-col>

          <b-col
            lg="6"
            cols="12"
          >
            <div class="block mb-1">
              <div class="mb-1 d-flex justify-content-center pt-2">
                <img
                  src="https://www.tode365.com/wp-content/uploads/2021/08/%E0%B8%98%E0%B8%81%E0%B8%AA.png"
                  alt=""
                  height="20"
                >&nbsp;
                <span class="text-white">
                  หวย ธกส.
                  {{ ResultTKSDate ? ResultTKSDate : "วว/ดด/ปปปป" }}</span>
              </div>
              <b-row>
                <b-col
                  lg="6"
                  cols="6"
                >
                  <h3 class="m-0 text-pink-1">
                    {{ ResultTKS.U3 ? ResultTKS.U3 : "xxx" }}
                  </h3>
                  <small>3ตัวบน</small>
                </b-col>

                <b-col
                  lg="6"
                  cols="6"
                >
                  <h3 class="m-0 text-pink-1">
                    {{ ResultTKS.D2 ? ResultTKS.D2 : "xx" }}
                  </h3>
                  <small>2ตัวล่าง</small>
                </b-col>
              </b-row>
            </div>
            <!-- End Lotto THAI -->
          </b-col>
          <b-col
            lg="12"
            cols="12"
          >
            <div
              class="block mb-1"
            >
              <h4 class="text-pink text-left ml-1">
                ผลสลากกินแบ่งรัฐบาลแต่ละงวด
              </h4>
              <b-row>
                <b-col>
                  <!-- striped small responsive="sm" -->
                  <b-table
                    class="mt-2"
                    responsive
                    dark
                    hover
                    :items="DataLotto"
                    :fields="fieldsShowLotto"
                  >

                    <template #cell(F3N1)="data">
                      {{ data.item.F3N1[0] }}, {{ data.item.F3N1[1] }}
                    </template>
                    <template #cell(F3N2)="data">
                      {{ data.item.F3N2[0] }} ,
                      {{ data.item.F3N2[1] }}
                    </template>
                  </b-table>

                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>

        <!-- ---- -->
        <div
          class="section-wrap section-normal block mt-2"
          style="margin: 110px auto 0 !important;"
        >

          <div class="mb-1 d-flex justify-content-center">
            <div class="head2">
              <img
                src="/blackpink/head/head2.png"
                alt=""
              >
              <!-- <h4
                style="width:300px;height:50px;margin-top:10px;"
                class="text-left text-pink ml-1"
              >

                ผลสลากกินแบ่งรัฐบาลแต่ละงวด
              </h4> -->
              <div
                style="width:300px;height:50px;margin-top:10px;z-index:99;"
                class="text-left text-pink ml-1"
              >
                <strong class="font-medium-2 text-pink">หวยหุ้น ประจำวันที่ {{ FindDate }}
                </strong>
                <i
                  class="fas fa-calendar rounded-circle bg-light"
                  style="padding: 5px;color:#800707;"
                  @click="showDatePicker"
                />
                <input
                  ref="datepicker"
                  v-model="FindDate"
                  type="date"
                  style="visibility: hidden;width: 0px;"
                  @change="GetDatas"
                >
                <!-- <div class="pointer d-flex align-items-center ml-1 ">
                  <i
                    class="fas fa-calendar rounded-circle bg-light"
                    style="padding: 5px;color:#800707;"
                    @click="showDatePicker"
                  />
                  <input
                    ref="datepicker"
                    v-model="FindDate"
                    type="date"
                    style="visibility: hidden;width: 0px;"
                    @change="GetDatas"
                  > -->
              </div>
            </div>

          <!-- <img
              src="icon/vb2.svg"
              alt=""
              height="50"
            > -->

          </div>

          <b-row class="mt-5">

            <b-col
              class="col-5"
            >

              <div
                v-for="(item, index) in LottoMenu"
                :key="index.id"
                class="pointer "
                :class="{ 'box-lotto': !item.isActive }"
                @click="toggleActive(item)"
              >
                <div
                  class="box-title"
                  :class="{ 'active-menu2': item.isActive, 'bg-pink-1': !item.isActive }"
                >
                  <img
                    v-if="item.img"
                    :src="`https://api.hapi-lot.com/api/get/img?pathfile=${EncodeBase64(item.img)}`"
                    width="25px"
                    class="mr-1"
                  > <span style="font-size:12px;">หวย/หุ้น
                    {{ item.name }}</span>
                </div>
              </div>
            </b-col>

            <b-col

              class="block-left"
            >
              <div class="row  ma-5">
                <div
                  v-for="(set, index) in list.filter(item => item.group_id == Trigger_manu)"
                  :key="index"
                  class="col-sm-12"
                >
                  <!-- {{ set.picture }} -->
                  <div class="box-lotto">
                    <div class="box-title bg-pink-1">
                      <img
                        v-if="set.picture"
                        :src="`https://api.hapi-lot.com/api/get/img?pathfile=${EncodeBase64(set.picture)}`"
                        height="25"
                        width="25"
                      ><strong style="margin-left: 5px">{{ set.name }}</strong>
                    </div>
                    <div class="box-inner">
                      <div class="inner-col">
                        <div class="sub-text">
                          สามตัวบน
                        </div>
                        <div
                          v-if="set.result && set.result.U3"
                          class="lot-num text-pink-1"
                          style="margin-top: 8px;color: #ffd5e8;"
                        >
                          {{ set.result.U3 }}
                        </div>
                        <div
                          v-else
                          class="lot-warning"
                        >
                          <i class="fas fa-exclamation-triangle" /> รอผล
                        </div>
                      </div>
                      <div class="inner-col">
                        <div class="sub-text">
                          สองตัวล่าง
                        </div>
                        <div
                          v-if="set.result && set.result.D2"
                          class="lot-num"
                          style="margin-top: 8px;color: #ffd5e8;"
                        >
                          {{ set.result.D2 }}
                        </div>
                        <div
                          v-else
                          class="lot-warning"
                        >
                          <i class="fas fa-exclamation-triangle" />รอผล
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        <!-- ยี่กี -->
        <!-- <div class="section-wrap section-normal mt-1">
            <div class="mb-1 mt-2">
              <strong
                class="font-medium-2"
              >จับยี่กี ประจำวันที่ {{ ResultYGTodayDate }}</strong>
            </div>
            <div class="row gutters-10">
              <div
                v-for="(yeeki, index) in ResultYGToday"
                :key="yeeki"
                class="col-md-4 col-lg-2"
              >
                <div class="box-lotto">
                  <div class="box-title yigy264">
                    <img
                      src="assets/themes/front2021/images/yigy_special.png"
                      class="img-title"
                    ><strong>จับยี่กี รอบที่ {{ index + 1 }}</strong>
                  </div>
                  <div class="box-inner">
                    <div class="inner-col">
                      <div class="sub-text">
                        สามตัวบน
                      </div>
                      <div
                        v-if="yeeki.U3 > 0"
                        class="lot-num"
                      >
                        {{ yeeki.U3 }}
                      </div>
                      <div
                        v-else
                        class="lot-warning"
                      >
                        <i class="fas fa-exclamation-triangle" />รอผล
                      </div>
                    </div>
                    <div class="inner-col">
                      <div class="sub-text">
                        สองตัวล่าง
                      </div>
                      <div
                        v-if="yeeki.D2 > 0"
                        class="lot-num"
                      >
                        {{ yeeki.D2 }}
                      </div>
                      <div
                        v-else
                        class="lot-warning"
                      >
                        <i class="fas fa-exclamation-triangle" />รอผล
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <!-- </div> -->
      </div></b-overlay></div></b-overlay></div>

  </b-overlay>
    <!-- <SmokeComponent /> -->
  </div>

  <!-- <this-footer /> -->
</template>

<script>
import SmokeComponent from './smoke.vue'

import {
  //   BCard,
  BRow,
  BCol,
  // BContainer,
  //   BFormGroup,
  // BFormInput,
  // BButton,
  //   BPagination,
  //   BTable,
  //   BFormSelect,
  BOverlay,
  BIconHourglassSplit,
  VBTooltip,
  BTable,
  // BCarousel,
  // BCarouselSlide,
  // BAvatar,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
// import vSelect from 'vue-select'
import 'animate.css'
import { required, min, length } from '@validations'
// import { ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
// import ThisHeader from './component/ThisHeader.vue'
// import ThisFooter from './component/ThisFooter.vue'
// import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
// import { component } from 'vue/types/umd'

export default {
  components: {
    // vSelect,
    // BFormSelect,
    // BCard,
    BRow,
    BCol,
    // BContainer,
    // BFormGroup,
    // BFormInput,
    // BButton,
    // BPagination,
    // BTable,
    // ValidationProvider,
    // ValidationObserver,
    BOverlay,
    BIconHourglassSplit,
    BTable,
    // DatePicker,
    // BCarousel,
    // BCarouselSlide,
    // BAvatar,
    // ThisHeader,
    // ThisFooter,
    SmokeComponent,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      required,
      min,
      length,
      ShowRound: false,
      show: false,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      DataLotto: [],
      pageOptions: [10, 15, 20],
      perPage: 10,
      datePicker: null,
      fieldsShowLotto: [
        {
          key: 'created_at',
          label: 'งวดที่',
          thStyle: { color: '#ffc6da', backgroundColor: 'transparent' },
          tdStyle: { color: '#ffffff' },
        },
        { key: 'R1', label: 'รางวัลที่ 1', thStyle: { color: '#ffc6da', backgroundColor: 'transparent' } },
        { key: 'F3N1', label: '3 ตัวหน้า', thStyle: { color: '#ffc6da', backgroundColor: 'transparent' } },
        { key: 'F3N2', label: '3 ตัวท้าย', thStyle: { color: '#ffc6da', backgroundColor: 'transparent' } },
        { key: 'U3', label: '3 ตัวบน', thStyle: { color: '#ffc6da', backgroundColor: 'transparent' } },
        { key: 'U2', label: '2 ตัวบน', thStyle: { color: '#ffc6da', backgroundColor: 'transparent' } },
        { key: 'D2', label: '2 ตัวล่าง', thStyle: { color: '#ffc6da', backgroundColor: 'transparent' } },
      ],

      LottoMenu: [
        { name: 'ไทย', img: 'th.svg', isActive: false },
        { name: 'ลาว', img: 'la.svg', isActive: false },
        { name: 'ฮานอย', img: 'vn.svg', isActive: false },
        { name: 'ญี่ปุ่น', img: 'jp.svg', isActive: false },
        { name: 'มาเลย์', img: 'my.svg', isActive: false },
        { name: 'จีน', img: 'cn.svg', isActive: false },
        { name: 'เกาหลี', img: 'kr.svg', isActive: false },
        { name: 'เยอรมัน', img: 'de.svg', isActive: false },
        { name: 'รัสเซีย', img: 'ru.svg', isActive: false },
        { name: 'อินเดีย', img: 'in.svg', isActive: false },
        { name: 'เมกา', img: 'us.svg', isActive: false },

      ],
      fields: [
        { key: 'index', label: 'ลำดับที่', visible: true },
        {
          key: 'LottoHead',
          label: 'หวย',
          formatter: value => this.NameLottoHead(value) || '-',
          visible: true,
        },
        {
          key: 'LottoSubHead',
          label: 'ประเภท',
          visible: true,
        },
        { key: 'RoundData.CloseDateName', label: 'งวดวันที่', visible: true },
        { key: 'U3', label: 'เลขท้าย 3 ตัว(บน)', visible: true },
        { key: 'U2', label: 'เลขท้าย 2 ตัว(บน)', visible: true },
        { key: 'D2', label: 'เลขท้าย 2 ตัว(ล่าง)', visible: true },
        // { key: 'R1', label: 'รางวัลที่ 1', visible: true },
        // { key: 'F3N1', label: 'เลขหน้า 3 ตัว ที่ 1', visible: true },
        // { key: 'F3N2', label: 'เลขหน้า 3 ตัว ที่ 2', visible: true },
        // { key: 'B3N1', label: 'เลขท้าย 3 ตัว ที่ 1', visible: true },
        // { key: 'B3N2', label: 'เลขท้าย 3 ตัว ที่ 2', visible: true },
        {
          key: 'created_at',
          label: 'เวลาออกผล',
          formatter: value => moment(value).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss'),
          visible: true,
        },
        // { key: 'addby', label: 'แก้ไขโดย', visible: true },
        // { key: 'approveby', label: 'ยืนยันโดย', visible: true },
        // { key: 'actions', label: 'เครื่องมือ', visible: true },
      ],
      /* eslint-disable global-require */
      items: [],
      UserData: JSON.parse(localStorage.getItem('userData')),
      LottoHead: null,
      LottoSubHead: null,
      LottoList: JSON.parse(localStorage.getItem('LottoList')),
      LottoSubList: null,
      RoundData: {},
      R1: null, // รางวัลที่ 1 [THG]
      F3N1: null, // เลขหน้า 3 ตัว ที่1 [THG]
      F3N2: null, // เลขหน้า 3 ตัว ที่2 [THG]
      B3N1: null, // เลขท้าย 3 ตัว ที่1 [THG]
      B3N2: null, // เลขท้าย 3 ตัว ที่2 [THG]
      U3: null, // 3 ตัวบน [THG]
      U2: null, // 2 ตัวบน [THG]
      D2: null, // 2 ตัวล่าง [THG]
      EditID: null,
      ResultThai: {},
      ResultThaiDate: null,
      ResultAom: {},
      ResultAomDate: null,
      ResultTKS: {},
      ResultTKSDate: null,
      list: [],
      ResultYGLast: {},
      ResultYGLastDate: null,
      ResultYGRoundNo: null,
      ResultYGToday: {},
      ResultYGTodayDate: moment().format('DD/MM/YYYY'),
      FindDate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      Trigger_manu: 0,
    }
  },
  computed: {
    visibleFields() {
      return this.fields.filter(field => field.visible)
    },
  },
  mounted() {
    this.GetListManu()
    this.GetLotto()
    this.GetDatas()
    this.GetResultThai(1, null)
    this.GetResultAom(3, null)
    this.GetResultTKS(2, null)
    this.GetResultYGLast()
    this.GetResultYG_Today()
  },
  methods: {
    EncodeBase64(val) {
      const encodedText = btoa(val)
      return encodedText
    },
    showDatePicker() {
      this.$refs.datepicker.showPicker()
    },
    async GetLotto() {
      try {
        const { data: ResData } = await this.$http.get(
          '/reward/getresult/thailotto?perpage=10',
        )
        if (ResData) {
          this.DataLotto = ResData.mes.map(item => ({
            created_at: moment(item.created_at).format(
              'DD/MM/YYYY',
            ),
            R1: item.R1,
            F3N1: [item.F3N1, item.F3N2],
            F3N2: [item.B3N1, item.B3N2],
            U3: item.U3,
            U2: item.U2,
            D2: item.D2,
          }))
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetListManu() {
      try {
        const { data: ResData } = await this.$http.get(
          '/lotto/listmanu',
        )

        if (ResData) {
          this.LottoMenu = ResData.map(item => ({
            name: item.prefix_name,
            img: item.img,
            ID: item.id,
            isActive: false,
          }))
          this.LottoMenu[0].isActive = true
          this.Trigger_manu = this.LottoMenu[0].ID
        }
      } catch (e) {
        console.log(e)
      }
    },
    toggleActive(clickedItem) {
      this.LottoMenu.forEach(item => {
        item.isActive = item === clickedItem
      })
      this.Trigger_manu = clickedItem.ID
      // console.log(clickedItem)
    },
    async GetDatas() {
      try {
        this.show = true
        const params = {
          date: this.FindDate,
        }
        const { data: ResData } = await this.$http.get(
          '/reward/getresult/all', { params },
        )

        if (ResData) {
          this.show = false
          this.list = ResData
        }
      } catch (e) {
        this.show = false
        console.log(e)
      }
    },
    async GetResultThai(LottoHead, LottoSubHead) {
      const params = {
        LottoHead,
        LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          '/Reward/showlast',
          { params },
        )

        if (ResData.success) {
          // console.log('TH', ResData.mes)
          this.ResultThai = ResData.mes
          this.ResultThaiDate = moment(ResData.mes.created_at).format(
            'DD/MM/YYYY',
          )
          // return ResData.mes
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetResultAom(LottoHead, LottoSubHead) {
      const params = {
        LottoHead,
        LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          '/Reward/showlast',
          { params },
        )

        if (ResData.success) {
          this.ResultAom = ResData.mes
          this.ResultAomDate = moment(ResData.mes.RoundData.CloseDate).format(
            'DD/MM/YYYY',
          )
          // return ResData.mes
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetResultTKS(LottoHead, LottoSubHead) {
      const params = {
        LottoHead,
        LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          '/Reward/showlast',
          { params },
        )

        if (ResData.success) {
          this.ResultTKS = ResData.mes
          this.ResultTKSDate = moment(ResData.mes.RoundData.CloseDate).format(
            'DD/MM/YYYY',
          )
          // return ResData.mes
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetResultYGLast() {
      try {
        const { data: ResData } = await this.$http.get(
          '/Reward/ShowLastYG',
        )

        if (ResData.success) {
          this.ResultYGLast = ResData.mes
          this.ResultYGRoundNo = ResData.RoundNo
          this.ResultYGLastDate = moment(
            ResData.mes.RoundData.CloseDate,
          ).format('DD/MM/YYYY')
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetResultYG_Today() {
      try {
        const { data: ResData } = await this.$http.get(
          '/Reward/YGToDay',
        )

        if (ResData.success) {
          this.ResultYGToday = ResData.mes
        }
      } catch (e) {
        console.log(e)
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}
</style>

<style scoped>
[dir] .table-dark {
  background-color: #ee0000 !important;
}

.block2 {
  position: relative;
  padding-bottom: 20px;
  /* background: linear-gradient(0deg, #000, #272727); */
  /* background-image: repeating-conic-gradient(#000 0% 25%, /#1a1a1a 0% 50%); */
  /* background-image: radial-gradient(#131313 2px, #0c0c0c 2px); */
  /* background-image: radial-gradient(ellipse farthest-corner at 10px 10px, #110a0c, #110a0c 50%, #000 50%); */

  background-size: 10px 10px;
  border: 2px solid #fd97ba;

  box-shadow: #77002a 2px 2px;
  border-radius: 10px;
  color: white;
}

.head1 {
  /* display: flex; */
  position: absolute;
  width: 200px;
  font-size: 14px;
  font-weight: 900;
  left: -5px;
  top: -122px;
  padding: 10px;
  color: #fd97ba;
  pointer-events: none;
}

.head2 {
  /* display: flex; */
  position: absolute;
  width: 200px;
  font-size: 14px;
  font-weight: 900;
  left: -5px;
  top: -118px;
  padding: 10px;
  color: #fd97ba;
  /* pointer-events: none; */
}

.header-tb {
  background-color: #e2dac3 !important;

  font-weight: 500 !important;

  @media (max-width: 767px) {
    font-size: 3vw;
  }
}

.head-tb {
  position: absolute;
  font-size: 14px;
  font-weight: 900;
  left: 14px;
  top: -9px;
  background-color: white;
  padding: 10px;
  color: #700000;
  border-top: solid 3px #8a0000;
  border-left: solid 3px #8a0000;
  border-right: solid 3px #8a0000;
  border-top-right-radius: 20px;
  border-top-left-radius: 10px;
}

.head-anouce {
  position: absolute;
  font-size: 14px;
  font-weight: 900;
  left: 14px;
  top: -20px;
  background-color: white;
  padding: 15px 15px 0px;
  color: #700000;
  border-top: solid 3px #8a0000;
  border-left: solid 3px #8a0000;
  border-right: solid 3px #8a0000;
  border-top-right-radius: 20px;
  border-top-left-radius: 10px;
}

.date-btn .mx-input {
  width: 0px !important;
}

.date-btn .mx-icon-calendar {
  display: inline-block;
  /* ทำให้ icon แสดงอยู่ในบรรทัดเดียวกับ input */
}

@media only screen and (max-width: 600px) {
  .promoBox {
    width: 10px !important;
  }
}

</style>
